@import "@alltrails/shared/styles/landsEnd.scss";
.borderBottom {
  border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
}

.question {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.onlyQuestion {
  @include text-200;
  margin-top: var(--space-300);
  margin-bottom: var(--space-300);
}

.answer {
  @include text-200;
  color: var(--color-text-secondary);
  padding-bottom: var(--space-300);
  white-space: pre-wrap;
  word-break: break-word;
}

.onlyAnswer {
  margin-top: var(--space-200);
  margin-bottom: var(--space-300);
}
