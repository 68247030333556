@import "@alltrails/shared/styles/landsEnd.scss";
@import '../layouts/layoutStyles.module.scss';

.locationFAQSection {
  @include page-width-lg {
    @include colLayout;
  }
}

.titleSection {
  @include page-width-lg {
    position: sticky;
    top: calc(var(--space-800) * 2);
    height: fit-content;
    @include colCount(2);
  }
}

.title {
  margin-right: var(--space-800);
}

.answers {
  border-top: var(--borders-weight-sm) solid var(--color-border-separator);

  @include page-width-lg {
    @include colCount(4);
  }
}
